<template>      
      <loader v-if="loading"></loader>
      <navbar @onClicked="scrollToSection"></navbar>
      <scrollbar></scrollbar>
      
      <div id="page" style="z-index: 1000">
         <!-- header -->
         <header class="header" style="margin-top: 25px;margin-left: -30px;">
            <div class="container" style="cursor: pointer">
               <div class="navbar-brand">
                  <div class="row">
                     <div class="col-xs-6">
                        <h1 class="text-left"></h1>
                     </div>
                  </div>                  
                  
                  <a href="#" class="js-nav-toggle" 
                     style="position: absolute;right:0;font-size: 20pt;" :style="{'right': isDesktop + 'svw'}">
                     <input id="checkbox" type="checkbox">
                     <label class="toggle" for="checkbox">
                        <div id="bar1" class="bars"></div>
                        <div id="bar2" class="bars"></div>
                        <div id="bar3" class="bars"></div>
                     </label>
                     <!-- <i class="fa-solid fa-bars menu-color"></i> -->
                  </a>
               </div>
            </div>
         </header>

         <a class="logo" href="/" style="position: absolute;top: 5svh;left: 10svw;z-index: 100;">
            <img src="/images/logo.png" style="width: 3svw">
         </a>
         <!-- End Header -->

         <!-- Banner section -->
         <section id="banner" class="js-fullheight">
            <div class="flexslider js-fullheight">
               <!-- Social -->
               <ul class="list-unstyled ml-5 mt-3 position-relative social-links">
                  <li class="mb-3"><a target="blank" href="https://github.com/grgustiawan"><i class="fab fa-github"></i></a></li>
                  <li class="mb-3"><a target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146"><i class="fab fa-linkedin"></i></a></li>
                  <li class="mb-3"><a target="blank" href="https://www.instagram.com/gr.gustiawan/"><i class="fab fa-instagram"></i></a></li>
               </ul>
               <!-- Slider -->
               <ul class="slides">
                  <li :class="{'bg-desktop': swidth > 768, 'bg-mobile': swidth < 767}">
                     <div class="overlay-gradient"></div>
                     <div class="container">
                        <div class="col-md-8 slider-text">
                           <div class="slider-text-inner">
                              <div class="desc">
                                 <h2>I'm Galih Raka Gustiawan!</h2>
                                 <h3>I am a
                                    <span id="typed-slide-1"></span>
                                    <span id="typed-strings-slide-1">
                                    <span>Full-Stack Engineer</span>
                                    <span>Software Engineer</span>
                                    </span>
                                 </h3>

                                 <button class="download-button">
                                    <a class="button_lg" style="text-decoration: none;"
                                       :href="downloadLink" download="CV_GALIH_RAKA_GUSTIAWAN.pdf">
                                        <span class="button_sl"></span>
                                        <span class="button_text">Download CV</span>
                                    </a>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
         </section>
         <!-- End Banner Section -->

         <!-- About Section -->
         <section id="about">
            <div class="container">
               <div class="row">
             
                  <div class="col-md-12">
                     <div class="post-entry">

                        <div class="col-md-6">
                            <div class="header-page-about">
                              <h2 class="intro-heading">About Me</h2>
                           </div>
                            <p>Hello, I'm Galih Raka Gustiawan, a Fullstack Software Engineer with a passion for crafting innovative and efficient digital solutions. My journey in software engineering has equipped me with a diverse skill set in programming languages such as JavaScript, TypeScript, GO, PHP, and Progress OpenEdge. I staying up-to-date with the latest trends and best practices to deliver cutting-edge solutions that meet and exceed client expectations.</p>
                           <div class="col-md-6 col-sm-4 no-padding">
                              <ul>
                                 <li><span>Name : </span> Galih Raka Gustiawan</li>
                                 <li><span>Job : </span> Full-Stack Engineer</li>
                              </ul>
                           </div>
                           <div class="col-md-6 col-sm-4 no-padding">
                              <ul>
                                 <li><span>Email : </span> <a href="mailto:galihrakagustiawan@gmail.com">galihrakagustiawan@gmail.com</a></li>
                                 <li><span>Phone : </span> <a href="tel:085179793322"> +62 851 7979 3322</a></li>
                              </ul>
                           </div>
                           <hr>
                           <div class="col-md-12 no-padding mt-20">
                              <a class="btn btn" target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146/">Profile</a>
                              <a class="btn btn-primary" href="tel:085179793322">Hire Me</a> 
                           </div>
                        </div>

                        <div class="col-md-6">
                           <div class="post-image" style="background-image: url('images/profile-pic.jpg');"></div>
                           <div class="display-tc">
                              <div class="desc">
                                 <h3>My Skill</h3>
                                 <div class="skill-item">
                                    <h5>Frontends</h5><span>70%</span>
                                    <div class="progress" data-percent="70%">
                                       <div class="progress-bar bar-null" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 70%;"></div>
                                    </div>
                                 </div>
                                 <div class="skill-item">
                                    <h5>Backends</h5><span>100%</span>
                                    <div class="progress" data-percent="100%">
                                       <div class="progress-bar bar-null" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                                    </div>
                                 </div>
                                 <div class="skill-item">
                                    <h5>DevOps</h5><span>80%</span>
                                    <div class="progress" data-percent="80%">
                                       <div class="progress-bar bar-null" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 80%;"></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </section>
         <!-- End About Section -->

         <!-- Resume Section -->
         <section id="resume" class="bg-dark bg-dots">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>My Resume</h2>
                        <span></span>
                     </div>
                     <span class="heading-meta-sub">RESUME</span>
                     <p>Results-driven Software Engineer with a years of experience. Proven track record of FullStack Engineer. Adept at JavaScript, TypeScript, and PHP.</p>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-12">
                     <div class="col-md-6">
                        <div class="item mb-50">
                           <h4><span class="item-icon"><i class="fas fa-briefcase"></i></span>Software Development Lead</h4>
                           <span class="meta">01.2024 - Now</span>
                           <h5>PT. SARI TIRTA INDONESIA (Melawai)</h5>
                           <p>
                              <ul style="margin-left: -20px;">
                                 <p>I am enthusiastic about driving technological advancements, staying abreast of industry trends, and ensuring that our software development practices remain cutting-edge. Through a combination of leadership, technical prowess, and a dedication to excellence, I am confident in my ability to elevate and advance the software development function within the organization.</p>
                              </ul>
                           </p>
                        </div>
                        <div class="item mb-50 mt-20">
                           <h4><span class="item-icon"><i class="fas fa-briefcase"></i></span>Software Development</h4>
                           <span class="meta">10.2022 - 01.2024</span>
                           <h5>PT. SARI TIRTA INDONESIA (Melawai)</h5>
                           <p>
                              <ul style="margin-left: -20px;">
                                 <li>Analysis of client needs and create a system design.</li>
                                 <li>Determine the technology stack to be used.</li>
                                 <li>Create a database structure.</li>
                                 <li>Developing Backend and Frontend Application.</li>
                                 <li>Integrating Application with third party vendor.</li>
                                 <li>Deploying apps to production.</li>
                              </ul>
                           </p>
                        </div>
                     </div>

                     <div class="col-md-6">
                        <div class="item mb-50">
                           <h4><span class="item-icon"><i class="fas fa-graduation-cap"></i></span>Teknik Informatika</h4>
                           <span class="meta">07.2017 - 08.2022</span>
                           <h5>Universitas Banten Jaya</h5>
                           <p>Master the art of software development, from conceptualization to implementation, honing skills vital for crafting robust and scalable solutions.</p>
                        </div>
                        <div class="item mb-50" style="margin-top: 65px">
                           <h4><span class="item-icon"><i class="fas fa-graduation-cap"></i></span>Teknik Komputer Jaringan</h4>
                           <span class="meta">07.2011 - 07.2014</span>
                           <h5>SMKN 1 Kota Serang </h5>
                           <p>Develop a deep understanding of network protocols, security, and administration, becoming a proficient architect of secure and efficient network infrastructures.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Resume Section -->

         <!-- Services Section -->
         <section id="services" class="bg-light">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>My Skill</h2>
                     </div>
                     <span class="heading-meta-sub">SKILL SET</span>
                     <p>
                        Software developer fluent in JavaScript, TypeScript, and PHP, adept at crafting efficient and innovative solutions.
                     </p>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fab fa-codepen"></i>
                        </div>
                        <div class="box-num">
                           <h2>01</h2>
                        </div>
                        <div class="text">
                           <h3>Design System</h3>
                           <p>Turn your vision into reality with precision-driven system design.</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fas fa-paint-brush"></i>
                        </div>
                        <div class="box-num">
                           <h2>02</h2>
                        </div>
                        <div class="text">
                           <h3>FrontEnd Application</h3>
                           <p>Developing FrontEnd Application using Vue, React and Next JS. </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fas fa-chart-line"></i>
                        </div>
                        <div class="box-num">
                           <h2>03</h2>
                        </div>
                        <div class="text">
                           <h3>System Optimization</h3>
                           <p>Enhanced performance through strategic optimization.</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fas fa-laptop-code"></i>
                        </div>
                        <div class="box-num">
                           <h2>04</h2>
                        </div>
                        <div class="text">
                           <h3>BackEnd Development</h3>
                           <p>Developing backend services using JavaScript, TypeScript, Python and PHP. </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service">
                        <div class="icon">
                           <i class="fas fa-desktop"></i>
                        </div>
                        <div class="box-num">
                           <h2>05</h2>
                        </div>
                        <div class="text">
                           <h3>Integrating Apps</h3>
                           <p>Integrating apps with third party vendor using api. </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service">
                        <div class="icon">
                           <i class="fas fa-rocket"></i>
                        </div>
                        <div class="box-num">
                           <h2>06</h2>
                        </div>
                        <div class="text">
                           <h3>Deploying Application</h3>
                           <p>Deploying apps to cloud server. </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Resume Section -->
         
         <!-- Counters Section -->
         <!-- <div class="counters" id="counter-animate">
            <div class="narrow-content">
               <div class="container">
               <div class="row" >
                  <div class="col-md-12"> 
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="7520" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Project</span>
                     </div>
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="5450" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Clients</span>
                     </div>
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="6876" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Happy Client</span>
                     </div>
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="456" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Awards </span>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div> -->
         <!-- End Counters Section -->

         <!-- Works Section -->
         <section id="work" class="bg-light">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>My Projects</h2>
                        <span></span>
                     </div>
                     <span class="heading-meta-sub">PORTFOLIO</span>
                     <p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 px-25 text-center">
                     <ul class="work-filter">
                        <li class="filter" :class="{'active' : selected_project == 'App'}" data-filter=".branding" @click="selected_project = 'App'">Web Application</li>
                        <li class="filter all" :class="{'active' : selected_project == 'Frontend'}" data-filter="*" @click="selected_project = 'Frontend'">Frontend</li>
                        <li class="filter" :class="{'active' : selected_project == 'Api'}" data-filter=".web" @click="selected_project = 'Api'">Backend</li>
                        <li class="filter" :class="{'active' : selected_project == 'Database'}" data-filter=".web" @click="selected_project = 'Database'">Database</li>
                     </ul>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'App'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all photo mobile">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/sos.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://tamani-pos.online">Self Ordering System</a>
                                    </h3>
                                    <span>Tamani Restaurant</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web branding">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/ess.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://saritirta-group.com">ESS</a>
                                    </h3>
                                    <span>Sari Tirta Indonesia</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/tamani-reservasi.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://reservasi.tamani-pos.online">Tamani Reservation</a>
                                    </h3>
                                    <span>Tamani Restaruant</span>
                                 </div>
                              </div>
                           </div>
                        </div>          
                        <div class="col-lg-4 col-md-6 col-sm-12 all branding web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/ecommerce.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://apotikmelawai.id">E-Commerce</a>
                                    </h3>
                                    <span>Apotik Melawai</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all photo mobile">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/procurement.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://procurement.saritirta-group.com">Procurement</a>
                                    </h3>
                                    <span>Movenpick</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all photo mobile">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/ose.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://ose.saritirta-group.com">OSE</a>
                                    </h3>
                                    <span>Movenpick</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/picking.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Picking Product</a>
                                    </h3>
                                    <span>Apotik Melawai</span>
                                 </div>
                              </div>
                           </div>
                        </div>  
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/menu-tamani.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Menu Book</a>
                                    </h3>
                                    <span>Tamani Restaurant</span>
                                 </div>
                              </div>
                           </div>
                        </div>  
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="/images/tamani_cms.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Tamani CMS</a>
                                    </h3>
                                    <span>Tamani Restaurant</span>
                                 </div>
                              </div>
                           </div>
                        </div>  
                     </div>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'Frontend'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="/images/reactjs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>React Js</a>
                                    </h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="/images/vuejs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Vue Js</a>
                                    </h3>
                                    <span>JavaScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="/images/nextjs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Next JS</a></h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'Api'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/expressjs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Express Js</a>
                                    </h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/nestjs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Nest JS</a>
                                    </h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>          
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/fastapi.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>FastAPI</a></h3>
                                    <span>Python</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/adonis.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Adonis JS</a></h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/laravel.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Laravel</a></h3>
                                    <span>PHP</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/pheonix.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Pheonix</a></h3>
                                    <span>Erlang</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/rocket.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Rocket</a></h3>
                                    <span>Rust</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/openedge.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Progress Opendge</a></h3>
                                    <span>Progress ABL</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/go.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>GO</a></h3>
                                    <span>GO</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'Database'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/sql-server.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Microsoft SQL Server</a>
                                    </h3>
                                    <span>Relational</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/postgre.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>PostgreSQL</a>
                                    </h3>
                                    <span>Relational</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/mongo.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Mongo DB</a></h3>
                                    <span>NoSQL</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/cassandra.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Apache Cassandra</a></h3>
                                    <span>Wide Column</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/mysql.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>MySQL</a></h3>
                                    <span>Relational</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Works Section -->

         <!-- Contact Section -->
         <section id="contact" class="bg-light">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>Get In Touch</h2>
                        <span></span>
                     </div>
                     <span class="heading-meta-sub">CONTACT</span>
                     <p>Have a question or need more information about my work or experiences? Feel free to ask!</p>
                  </div>
               </div>
               <div class="row" style="display: flex;justify-content: space-between;">
                  <div class="col-md-4 col-md-push-1">
                     <ul class="contact-info">
                        <li><i class="fas fa-map-marked-alt"></i>Serang, Banten - Indonesia</li>
                        <li><i class="fas fa-phone-alt"></i>+62 851-7979-3322</li>
                        <li><i class="fas fa-envelope"></i><a href="mailto:galihrakagustiawan@gmail.com">galihrakagustiawan@gmail.com</a></li>
                        <li><i class="fas fa-globe"></i><a href="https://galihrakagustiawan.site">www.galihrakagustiawan.site</a></li>
                     </ul>
                  </div>
                  <div class="col-md-5 col-md-push-1">
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <input type="text" class="form-control" placeholder="Name" v-model="name">
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <input type="text" class="form-control" placeholder="Email" v-model="email">
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <textarea name="message" class="form-control" id="message" cols="30" rows="7" placeholder="Message" v-model="message"></textarea>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group text-right">
                              <input type="submit" value="Send Message" class="btn btn-primary" @click="sendEmail">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Contact Section -->

         <!-- Footer -->
         <footer>
            <div id="footer">
               <div class="container">
                  <div class="row copy-right">
                     <div class="col-md-6 col-md-offset-3 text-center">
                        <h2><a class="footer-logo">Galih Raka Gustiawan</a></h2>
                        <p class="social-icon">
                           <a target="blank" href="https://github.com/grgustiawan"><i class="fab fa-github"></i></a>
                           <a target="blank" href="https://www.instagram.com/gr.gustiawan"><i class="fab fa-instagram"></i></a>
                           <a target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146"><i class="fab fa-linkedin"></i></a>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <a id="back-to-top" href="#"><i class="fas fa-chevron-up"></i></a>
         </footer>
         <!-- End Footer -->
      </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Navbar from '@/components/Navbar.vue'
import Scrollbar from '@/components/Scrollbar.vue'
import emailjs from 'emailjs-com'

export default {
  name: 'HomeView',
  components: {
    Loader,
    Navbar,
    Scrollbar,
  },
  data(){
    return {
      loading: true,
      selected_project: "App",
      swidth: 0,
      downloadLink: 'https://galihrakagustiawan.site/files/CV_GALIH_RAKA_GUSTIAWAN.pdf',
      name: null,
      email: null,
      message: null,
      isDesktop: -5,
    }
  },
  created(){
   this.swidth = screen.width
   if(this.swidth < 768){
      this.isDesktop = 0;
   }

   setTimeout(() => {
   this.loading = false
   }, 1500)
  },
  methods: {
      scrollToSection(value) {
         document.body.classList.remove('menu-show')
         document.getElementById("navButton").classList.remove('active')

         let unit = value.unit
         if(screen.height <= 720){
            unit += 6
         }

         const offset = -(screen.height / unit);
         const section = document.getElementById(value.id);
         if(section) {
            const scrollPosition = section.offsetTop + (offset || 0);
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
         }
      },
      sendEmail() {
         const templateParams = {
            to_name: 'Galih Raka Gustiawan',
            from_name: this.name,
            message: this.message,
            reply_to: this.email
         };

         emailjs.send('service_0st7bwz', 'template_pemkwvo', templateParams, 'ZABaGgpX2DnMNZl_W')
         .then((response) => {
            alert('Email sent successfully:', response);
         })
         .catch((error) => {
            alert('Error sending email:', error);
         });
      }
  }
}
</script>

<style>
.bg-desktop{
   background-image: url('../assets/images/slide_1.jpg');
}

.bg-mobile{
   background-image: url('../assets/images/slide_2.jpg');
}
</style>