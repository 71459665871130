<template>
    <div class="scroll-icon">
        <a href="#info" class="smoothscroll">
            <div class="mouse"></div>
        </a>
        <div class="end-top"></div>
    </div>
</template>

<script>
export default {
    name: 'ScrollbarVue'
}
</script>

<style>

</style>