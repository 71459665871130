<template>
    <nav id="main-nav">
        <a href="#" id="navButton" class="js-nav-toggle nav-toggle active"><i></i></a>
        <div class="js-fullheight table">
        <div class="table-cell js-fullheight">
            <ul>
                <li><a style="cursor: pointer;" @click="setNavigation('banner', 0)">Home</a></li>
                <li><a style="cursor: pointer;" @click="setNavigation('about', 14)">About Me</a></li>
                <li><a style="cursor: pointer;" @click="setNavigation('resume', 100)">Resume</a></li>
                <li><a style="cursor: pointer;" @click="setNavigation('services', 100)">My Skill</a></li>
                <li><a style="cursor: pointer;" @click="setNavigation('work', 100)">My Work</a></li>
                <li><a style="cursor: pointer;" @click="setNavigation('contact', 300)">Contact Me</a></li>
            </ul>
            <!-- Social -->
            <p class="social-icon">
                <!-- <a href="#"><i class="fab fa-facebook-square"></i></a> -->
                <a target="blank" href="https://github.com/grgustiawan"><i class="fab fa-github"></i></a>
                <a target="blank" href="https://www.instagram.com/gr.gustiawan"><i class="fab fa-instagram"></i></a>
                <a target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146"><i class="fab fa-linkedin"></i></a>
            </p>
        </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarVue',
    methods: {
        setNavigation(id, unit){
            const data = {
                id: id,
                unit: unit
            }
            this.$emit('onClicked', data);
        }
    }
}
</script>