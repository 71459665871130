<template>
    <div id="preloader">
        <div class="pre-container">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoaderVue',
}
</script>

<style>

</style>